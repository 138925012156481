import cls from 'classnames';
import { useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { TooltipControl } from 'src/components/base/Tooltip';
import { useModalDialog, ConfirmModalDialog } from 'src/components/base/ModalDialog';
import { hasModule } from 'src/components/Trap/utils';
import classes from './Elements.module.scss';

export const EventStatus = ({ title, subtitle }) => {
  return [title, subtitle].filter(Boolean).join(' ');
}

const eventPriorityIcons = { normal: classes.eventPriorityNormal, alarm: classes.eventPriorityAlarm, high: classes.eventPriorityHigh };
export const EventPriority = ({ priority }) => {
  const className = useMemo(() => cls(classes.eventPriorityIcon, eventPriorityIcons[priority]), [priority]);
  return <div className={className} />;
}

const eventSignalIcons = { off: classes.eventSignalOff, 0: classes.eventSignal0, 1: classes.eventSignal1, 2: classes.eventSignal2, 3: classes.eventSignal3 };
export const EventSignal = ({ signal, rssi }) => {
  const { className, tooltip } = useMemo(() => {
    const value = signal > 0 && Math.min(3, Math.floor((signal - 1) / 25));
    return {
      className: cls(classes.eventSignalIcon, value === false ? eventSignalIcons.off : eventSignalIcons[value]),
      tooltip: !isNaN(parseFloat(rssi)) && `${rssi}`,
    };
  }, [signal, rssi]);

  return (
    <TooltipControl tooltip={tooltip}>
      <div className={className} />
    </TooltipControl>
  )
}

const eventBatteryIcons = { off: classes.eventBatteryOff, 0: classes.eventBattery0, 1: classes.eventBattery1, 2: classes.eventBattery2, 3: classes.eventBattery3 };
export const EventBattery = ({ battery, voltage }) => {
  const { className, tooltip } = useMemo(() => {

    const rawBattery = parseFloat(battery);
    const value = !isNaN(rawBattery) && Math.min(3, Math.floor(rawBattery / 25));
    return {
      className: cls(classes.eventBatteryIcon, value === false ? eventBatteryIcons.off : eventBatteryIcons[value]),
      tooltip: !isNaN(parseFloat(voltage)) && `${voltage} mV`,
    };
  }, [battery, voltage]);

  return (
    <TooltipControl tooltip={tooltip}>
      <div className={className} />
    </TooltipControl>
  )
}

export const TrapSwitcher = ({ type, value, onChange, className }) => {
  const modal = useModalDialog();

  async function handleOnChange() {
    const text = !value
      ? (type === 'trapsensor'
        ? __('The trap is now switched ON in the system. Please note that the device will not start to communicate normally until you have loaded/released the metal bar.')
        : __('The module is now switched ON in the system. Please note that the module will not start to communicate normally until you have activated it with a magnet.')
      )
      : __('The module is now switched OFF in the system. The module will go into a sleep state next time it reports to the server.');

    if (await modal.show({ text })) {
      onChange(!value);
    }
  }

  if (!hasModule(type)) { return null }

  return (
    <>
      <Form.Check className={cls(className, 'fs-4')} type="switch" id="switch-trap" checked={value} onChange={handleOnChange} data-interact={true} />
      <ConfirmModalDialog variant="primary" actionTitle={__('Proceed')} {...modal.register()} />
    </>
  )
}
