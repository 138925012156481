import qs from 'qs';
import cls from 'classnames';
import { useEffect, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Form, FloatingLabel, Button } from 'react-bootstrap';

import { useModalDialog } from 'src/components/base/ModalDialog';
import { SettingsForm } from 'src/components/base/SettingsForm';
import { GroupSelector } from 'src/components/Groups/GroupSelector';
import { LocationDialog } from './LocationDialog';

import { useEffectState, useReplaceHistory, useFormatLocalization } from 'src/utils/hooks';
import { validatorResolver } from 'src/utils/validator';
import { toastSuccess } from 'src/utils/toast';

import { useFetchTrap, useUpdateTrap, useDeleteTrap } from 'src/model/traps';
import { useWorkspace, useCurrentWorkspace } from 'src/model/workspaces';
import { hasPermission, PERMISSION_TRAP_EDIT, PERMISSION_TRAP_EDIT_SWAP, PERMISSION_TRAP_EDIT_MAP } from 'src/api/permissions';
import { TRAP_TILTS, hasModule } from './utils';
import { TRAP_TILT, SERIAL_MIN_LENGTH } from 'src/constants';
import classes from './TrapSettings.module.scss';

const DEFAULT_TILT = TRAP_TILTS[2];

export const TrapSettings = () => {
  const { trap_types = [] } = useWorkspace();
  const form = useForm({
    resolver: validatorResolver({
      trap_name: ['required', { max_length: 40 }], trap_type: 'required', battery_type: 'string',
      serial_no: { predicate: (serial = '', { trap_type }) => (hasModule(trap_type) && serial.length < SERIAL_MIN_LENGTH) ? 'INVALID_SERIAL' : '' },
      tilt: 'integer', latitude: { latitude: 'longitude' }, longitude: { longitude: 'latitude' }, notes: 'string', is_switched_on: { one_of: [true, false] },
    }),
    defaultValues: { trap_type: trap_types[0]?.type, tilt: DEFAULT_TILT }
  });
  const isInvalid = Object.keys(form.formState.errors).length > 0;

  const { location } = useHistory();
  const { group_id } = qs.parse(location.search, { ignoreQueryPrefix: true });

  const params = useParams();
  const trap_id = !isNaN(Number.parseInt(params.id)) ? +params.id : undefined;
  const replaceHistory = useReplaceHistory(group_id !== undefined ? `/traps/${group_id}` : `/trap/${trap_id}`);

  const { data: trap } = useFetchTrap({ trap_id });
  const { workspace: { workspace_id, permissions } } = useCurrentWorkspace(trap.workspace_id);

  const [updateTrapAction] = useUpdateTrap();
  const [deleteTrapAction] = useDeleteTrap();

  const [settings, setSettings] = useEffectState({ ...trap, tilt: trap.tilt || DEFAULT_TILT }, [trap]);
  const [selectedRowIds, setSelectedRowIds] = useEffectState(() => {
    return group_id > 0 ? { [group_id]: true } : Object.fromEntries((trap.groups ?? []).map(item => [item, true]))
  }, [trap, group_id]);

  useEffect(() => { trap_id && form.reset(settings) }, [settings]);

  async function handleSubmitForm(data) {
    setSettings(data);

    const { error } = await updateTrapAction({ ...data, trap_id, workspace_id, groups: Object.keys(selectedRowIds).map(item => +item) });
    if (!error) {
      toastSuccess(__('Your changes were saved.'));
      replaceHistory();
    }
  }

  async function handleOnDelete(modal) {
    const text = <>{__('Are you sure you want to delete')} <b>{trap.trap_name}</b>?</>;
    if (await modal.show({ text })) {
      const { error } = await deleteTrapAction({ trap_id });
      if (!error) { replaceHistory('/traps/0') }
    }
  }

  return (
    <SettingsForm title={<TrapTitle trap={trap} />} deleteTitle={__('Delete Trap')} autoComplete="on"
      onSubmit={form.handleSubmit(handleSubmitForm)} onCancel={replaceHistory}
      onDelete={hasPermission(permissions, PERMISSION_TRAP_EDIT) && trap_id && handleOnDelete}>

      <SettingsForm.Tab title={__('Settings')} isInvalid={isInvalid}>
        <Settings form={form} permissions={permissions} trap={trap} />
      </SettingsForm.Tab>

      <SettingsForm.Tab title={__('Groups')}>
        <GroupSelector workspace_id={workspace_id} selectedRowIds={selectedRowIds} onSelectedRowsChange={setSelectedRowIds} />
      </SettingsForm.Tab>

    </SettingsForm>
  )
}

const Settings = ({ form, permissions, trap: { trap_id, trap_name, available_types, available_battery_types } }) => {
  const modal = useModalDialog();

  const { register, formState: { errors } } = form;
  const [trapType, batteryType, latitude, longitude, isSwitchedOn] = form.watch(['trap_type', 'battery_type', 'latitude', 'longitude', 'is_switched_on']);

  const { trap_types = [], battery_types = [], enable_payments = true, enable_sim_recurring_payments = false } = useWorkspace();

  const isReadonly = !hasPermission(permissions, PERMISSION_TRAP_EDIT);
  const isSerialReadonly = isReadonly && !hasPermission(permissions, PERMISSION_TRAP_EDIT_SWAP);
  const isCoordinatesReadonly = isReadonly && !hasPermission(permissions, PERMISSION_TRAP_EDIT_MAP);
  const isCoordinatesSet = latitude !== null && longitude !== null;

  const trapTypes = useMemo(() => {
    const keys = Object.fromEntries(trap_types.map(({ type, title }) => [type, title]));
    return (available_types ?? Object.keys(keys)).map(type => ({ type, title: keys[type] ?? type }));
  }, [available_types, trap_types]);

  const batteryTypes = useMemo(() => battery_types.filter(({ type }) => {
    const types = new Set(available_battery_types ?? trap_types.filter(({ type }) => type === trapType).flatMap(({ battery_types }) => battery_types));
    return types.has(type);
  }), [trapType, available_battery_types, battery_types]);

  useEffect(() => {
    if (!battery_types.length && !!batteryType) {
      form.setValue('battery_type', null);
    }
  }, [batteryType, battery_types]);

  // const errorLatitude = useFormatLocalization(__('Invalid value. Should be number in the range {min}–{max}.'), { min: '-90', max: '+90' });
  // const errorLongitude = useFormatLocalization(__('Invalid value. Should be number in the range {min}–{max}.'), { min: '-180', max: '+180' });

  async function handleChangeLocation() {
    const [lat, lng] = form.getValues(['latitude', 'longitude']);
    const result = await modal.show({ title: trap_name, location: { lat, lng } });
    if (result) {
      form.setValue('latitude', result.lat);
      form.setValue('longitude', result.lng);
    }
  }

  async function handleSwitchOn() {
    form.setValue('is_switched_on', !isSwitchedOn);
  }

  return (
    <>
      <Form.Group className="mb-3" controlId="trap_name">
        <FloatingLabel label={__('Trap Name')}>
          <Form.Control type="text" name="trap_name" placeholder={__('Trap Name')}
            {...register('trap_name')} isInvalid={!!errors.trap_name} readOnly={isReadonly} maxLength={40}
          />
          <Form.Control.Feedback type="invalid">{__('Name is required')}</Form.Control.Feedback>
        </FloatingLabel>
      </Form.Group>

      {hasModule(trapType) && trap_id &&
        <Form.Group className="mb-3" controlId="is_switched_on">
          <div className={classes.switchOnOffContainer}>
            <p className={classes.switchOnOffLabel}>{__('Device ON/OFF')}</p>
            <Form.Check name="is_switched_on" {...register('is_switched_on')} className={cls('fs-4', classes.switchOnOffCheck)} type="switch" id="switch-trap" checked={isSwitchedOn} data-interact={true} onChange={value => handleSwitchOn()} />
          </div>
        </Form.Group>
      }

      {hasModule(trapType) &&
        <Form.Group className="mb-3" controlId="serial_no">
          <FloatingLabel label={__('Serial Number')}>
            <Form.Control type="text" name="serial_no" placeholder={__('Serial Number')}
              {...register('serial_no')}
              isInvalid={!!errors.serial_no}
              readOnly={isSerialReadonly}
            />
            <Form.Control.Feedback type="invalid">{__('Serial Number is required')}</Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>
      }

      <Form.Group className="mb-3" controlId="trap_type">
        <FloatingLabel label={__('Trap Type')}>
          <Form.Select aria-label={__('Trap Type')} {...register('trap_type')} disabled={isReadonly}>
            {trapTypes.map(({ type, title }) => <option key={type} value={type}>{title}</option>)}
          </Form.Select>
        </FloatingLabel>
      </Form.Group>

      {trapType === TRAP_TILT &&
        <Form.Group className="mb-3" controlId="tilt">
          <FloatingLabel label={__('Tilt')}>
            <Form.Select aria-label={__('Tilt')} {...register('tilt')} disabled={isReadonly}>
              {TRAP_TILTS.map(tilt => <option key={tilt} value={tilt}>{tilt}°</option>)}
            </Form.Select>
          </FloatingLabel>
        </Form.Group>
      }

      {batteryTypes.length > 1 &&
        <Form.Group className="mb-3" controlId="battery_type">
          <FloatingLabel label={__('Battery Type')}>
            <Form.Select aria-label={__('Battery Type')} {...register('battery_type')} disabled={isReadonly}>
              {batteryTypes.map(({ type, title }) => <option key={type} value={type}>{title}</option>)}
            </Form.Select>
          </FloatingLabel>
        </Form.Group>
      }

      {/* <Form.Group className="mb-3" controlId="latitude">
        <FloatingLabel label={__('Latitude')}>
          <Form.Control type="text" name="latitude" placeholder={__('Latitude')}
            {...register('latitude')}
            isInvalid={!!errors.latitude}
            readOnly={isCoordinatesReadonly}
          />
          <Form.Control.Feedback type="invalid">{errorLatitude}</Form.Control.Feedback>
        </FloatingLabel>
      </Form.Group> */}

      {/* <Form.Group className="mb-3" controlId="longitude">
        <FloatingLabel label={__('Longitude')}>
          <Form.Control type="text" name="longitude" placeholder={__('Longitude')}
            {...register('longitude')}
            isInvalid={!!errors.longitude}
            readOnly={isCoordinatesReadonly}
          />
          <Form.Control.Feedback type="invalid">{errorLongitude}</Form.Control.Feedback>
        </FloatingLabel>
      </Form.Group> */}

      <Form.Group className="mb-3" controlId="notes">
        <FloatingLabel label={__('Notes')}>
          <Form.Control as="textarea" style={{ height: '6rem' }} name="notes" placeholder={__('Notes')} {...register('notes')} readOnly={isSerialReadonly} />
        </FloatingLabel>
      </Form.Group>

      <Form.Group className="mb-3">
        <Button onClick={handleChangeLocation} disabled={isCoordinatesReadonly}>{(trap_id && isCoordinatesSet && __('Change Trap Location on Map')) || __('Set location on map')}</Button>
      </Form.Group>

      <LocationDialog {...modal.register()} />

      {enable_payments && enable_sim_recurring_payments && <p>{__('You have enabled recurring payment. By continuing you accept that subscription for this new trap will be renewed automatically together with your other subscriptions. In order to stop recurring subscription, please disable it in the Subscription settings.')}</p>}
    </>
  )
}

const TrapTitle = ({ trap }) => {
  if (!trap.trap_id) { return __('New Trap') }

  const { trap_name, firmware, ccid } = trap;
  return (
    <div className='d-flex flex-row justify-content-between'>
      <span>{trap_name}</span>

      <div className='d-flex flex-column justify-content-end align-items-end'>
        {!!firmware && <span className='fs-sm text-muted'>{__('Firmware version')}: {firmware}</span>}
        {!!ccid && <span className='fs-sm text-muted'>{__('SIM card IMEI number')}: {ccid}</span>}
      </div>
    </div>
  )
}